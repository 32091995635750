import { cva } from 'class-variance-authority';
export const checkboxWrapperStyles = cva('group/checkbox flex flex-row items-center hover:cursor-pointer', {
    variants: {
        isDisabled: {
            true: 'hover:cursor-not-allowed',
            false: 'cursor-pointer',
        },
    },
});
export const checkboxContainerStyles = cva('transition-colors bg-transparent duration-150 group-active/checkbox:p-control-state-layer p-control-state-layer pointer-events-none box-border select-none ::before:box-border ::after:box-border rounded-control-checkbox-state-layer leading-none group-hover/checkbox:bg-control-state-layer-hover group-active/checkbox:bg-control-state-layer-press', {
    variants: {
        isDisabled: {
            true: 'group-hover/checkbox:bg-transparent group-active/checkbox:bg-transparent',
        },
    },
});
export const checkboxRootStyles = cva("w-control-container-xxxs h-control-container-xxxs pointer-events-auto focus-visible:outline-control-state-layer-focus focus-visible:outline-2 focus-visible:outline-offset-[5px] flex justify-center items-center border-component-secondary rounded-control-checkbox-container border-control data-[state='checked']:bg-control-container data-[state='checked']:icon-control-primary  data-[state='indeterminate']:bg-control-container data-[state='indeterminate']:icon-control-primary focus-visible:outline", {
    variants: {
        isDisabled: {
            true: "border-disabled data-[state='unchecked']:border-control-container-disabled data-[state='checked']:bg-control-container-disabled data-[state='indeterminate']:bg-control-container-disabled data-[state='checked']:border-none data-[state='indeterminate']:border-none",
            false: '',
        },
    },
});
export const checkboxIndicatorStyles = cva('flex justify-center items-center max-w-[12px] max-h-[12px]');
export const checkboxLabelStyles = cva('text-label-lg', {
    variants: {
        isDisabled: {
            false: 'text-control-primary',
            true: 'text-control-disabled pointer-events-none',
        },
    },
});
