'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cn } from '../../utils/cn';
import { DropdownMenuAnatomy as Anatomy } from './DropdownMenu.anatomy';
export const DropdownMenu = ({ className, leftIcon, rightIcon, size, children, hint, name, id, label, disabled, isOptional, ...props }) => {
    return (_jsxs(Anatomy.Root, { modal: false, size: size, disabled: disabled, className: className, ...props, children: [!!label ||
                (!!isOptional && (_jsxs("div", { className: cn('flex justify-between', 'gap-200'), children: [!!label && (_jsx(Anatomy.Label, { label: label, name: name, size: size, isDisabled: disabled })), !!isOptional && _jsx(Anatomy.Hint, { className: "ml-auto", children: "Optional" })] }))), children, !!hint && _jsx(Anatomy.Hint, { children: hint })] }));
};
export const DropdownItem = Anatomy.Item;
export const DropdownTrigger = Anatomy.Trigger;
export const DropdownIcon = Anatomy.Icon;
export const DropdownContent = Anatomy.Content;
export const DropdownGroup = Anatomy.ItemGroup;
export const DropdownGroupLabel = Anatomy.GroupLabel;
export const DropdownSeparator = Anatomy.ItemSeparator;
export const DropdownRadioGroup = Anatomy.RadioGroup;
export const DropdownRadioItem = Anatomy.RadioItem;
export const DropdownCheckboxItem = Anatomy.CheckboxItem;
export const DropdownSubmenu = Anatomy.Sub;
export const DropdownSubmenuTrigger = Anatomy.SubTrigger;
export const DropdownSubmenuContent = Anatomy.SubContent;
export const DropdownPortal = Anatomy.Portal;
