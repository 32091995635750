'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Indicator, Root } from '@radix-ui/react-checkbox';
import { forwardRef, useId } from 'react';
import { cn } from '../../utils/cn';
import { checkboxContainerStyles, checkboxIndicatorStyles, checkboxLabelStyles, checkboxRootStyles, checkboxWrapperStyles, } from './Checkbox.styles';
export function CheckboxIcon({ className, ...props }) {
    return (_jsx("svg", { className: className, width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", role: "presentation", ...props, children: _jsx("path", { d: "M4 13L9 18L20 6", stroke: "currentColor", strokeWidth: 4, strokeLinecap: "round", strokeLinejoin: "round" }) }));
}
export function IndeterminateIcon({ className, ...props }) {
    return (_jsx("svg", { className: className, width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", role: "presentation", ...props, children: _jsx("path", { d: "M5 12H12H19", stroke: "currentColor", strokeWidth: 4, strokeLinecap: "round", strokeLinejoin: "round" }) }));
}
export const Checkbox = forwardRef(({ checked, id: __id, children, className, indicatorContainerClass, disabled: isDisabled, ...props }, ref) => {
    const id = __id || `checkbox:${useId()}`;
    return (_jsxs("label", { htmlFor: id, "data-component": "checkbox", "data-part": "wrapper", className: checkboxWrapperStyles({ isDisabled }), children: [_jsx("div", { "data-part": "container", className: cn(checkboxContainerStyles({ isDisabled }), indicatorContainerClass), children: _jsx(Root, { "data-part": "item", className: cn(checkboxRootStyles({ isDisabled }), className), ref: ref, id: id, ...props, checked: checked, disabled: isDisabled, children: _jsxs(Indicator, { "data-part": "indicator", className: checkboxIndicatorStyles(), children: [checked === true && _jsx(CheckboxIcon, { className: 'w-full h-full' }), checked === 'indeterminate' && _jsx(IndeterminateIcon, { className: 'w-full h-full' }), typeof checked === 'undefined' && _jsx(CheckboxIcon, { className: 'w-full h-full' })] }) }) }), !!children && (_jsx("div", { "data-part": "label", className: cn(checkboxLabelStyles({ isDisabled })), children: children }))] }));
});
