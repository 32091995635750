'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from '@radix-ui/react-select';
import { Children, cloneElement, forwardRef, useEffect, useId, useState } from 'react';
import { CheckLine, ChevronDownLine, ChevronUpLine } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { getComponentState } from '../../utils/getComponentState';
import { FormControl } from '../FormControl';
import { InputLabel } from '../InputLabel';
import { callAll, z } from '../Primitive';
import { SelectGroupProvider, SelectProvider, useSelect, useSelectGroup } from './Select.context';
import { selectStyles } from './Select.styles';
const Root = forwardRef(({ children, className, name, id = `select:${useId()}`, size = 'md', isInvalid, disabled, isSuccess, useIndicator, value, onChange: onChangeProp, onValueChange, ...props }, ref) => {
    const [selectValue, setSelectValue] = useState(value ?? props.defaultValue);
    useEffect(() => {
        setSelectValue(value);
    }, [value, setSelectValue]);
    function handleChange(value) {
        onChangeProp?.({
            target: {
                value: value,
                name,
            },
        });
    }
    return (_jsx(SelectProvider, { value: {
            size,
            name,
            id,
            selectValue,
            setSelectValue,
            disabled,
            isInvalid,
            isSuccess,
            useIndicator,
        }, children: _jsx("div", { "data-scope": "select", "data-part": "root", className: cn(selectStyles.wrapper(), className), ref: ref, translate: "no", children: _jsx(S.Root, { ...props, value: selectValue, onValueChange: callAll(setSelectValue, onValueChange, handleChange), children: children }) }) }));
});
const Trigger = forwardRef(({ children, className, ...props }, ref) => {
    const { id, name, size, disabled } = useSelect();
    return (_jsx(S.Trigger, { "data-scope": "select", "data-part": "trigger", className: cn(selectStyles.trigger({
            size,
        }), className), id: id, disabled: disabled, name: name, ...props, ref: ref, children: children }));
});
const Icon = forwardRef(({ children, className, ...props }, ref) => {
    const { size } = useSelect();
    return (_jsx(S.Icon, { ref: ref, "data-scope": "select", "data-part": "icon", ...props, className: cn(selectStyles.icon({
            size,
        }), className), children: cloneElement(Children.only(children), {
            className: cn(selectStyles.icon({
                size,
            }), className),
        }) }));
});
const Value = forwardRef(({ className, ...props }, ref) => {
    const { id } = useSelect();
    return (_jsx(S.Value, { "data-scope": "select", "data-part": "value", id: id, className: className, ref: ref, ...props }));
});
/**
 * Content container element of the select items
 */
const Content = forwardRef(({ children, className, viewportClassName, ...props }, ref) => {
    const { size } = useSelect();
    return (_jsx(S.Portal, { children: _jsxs(S.Content, { "data-scope": "select", "data-part": "content", className: cn(selectStyles.content({
                size,
            }), className), ref: ref, ...props, children: [_jsx(S.ScrollUpButton, { className: cn(selectStyles.item({
                        size,
                    }), 'flex items-center justify-center'), children: _jsx(ChevronUpLine, { className: selectStyles.icon({
                            size,
                        }) }) }), _jsx(S.Viewport, { className: viewportClassName, children: Children.map(children, child => child &&
                        cloneElement(child, {
                            size,
                        })) }), _jsx(S.ScrollDownButton, { className: cn(selectStyles.item({
                        size,
                    }), 'flex items-center justify-center'), children: _jsx(ChevronDownLine, { className: selectStyles.icon({
                            size,
                        }) }) })] }) }));
});
/**
 * Option group for the Select element
 */
const ItemGroup = forwardRef(({ children, className, type, ...props }, ref) => {
    return (_jsx(SelectGroupProvider, { value: { type }, children: _jsx(S.Group, { "data-scope": "select", "data-part": "itemgroup", className: cn(selectStyles.group(), className), ref: ref, ...props, children: children }) }));
});
const Item = forwardRef(({ children, size: itemSize, type: itemType, leftIcon, showSelected = false, className, ...props }, ref) => {
    const { size: contextSize, useIndicator } = useSelect();
    const { type: groupType } = useSelectGroup();
    const size = itemSize ?? contextSize;
    const type = itemType ?? groupType;
    return (_jsxs(S.Item, { "data-scope": "select", "data-part": "item", className: cn(selectStyles.item({
            size,
            type,
        }), className), ref: ref, ...props, children: [useIndicator && (_jsx(z.div, { className: cn(selectStyles.icon({ size })), children: _jsx(S.ItemIndicator, { children: _jsx(CheckLine, { className: cn(selectStyles.icon({ size })) }) }) })), leftIcon &&
                cloneElement(leftIcon, {
                    className: selectStyles.icon({
                        size,
                    }),
                }), _jsx(S.ItemText, { children: children })] }));
});
const GroupLabel = forwardRef(({ className, children, ...props }, ref) => {
    const { id, size } = useSelect();
    return (_jsx(S.Label, { "data-scope": "select", "data-part": "grouplabel", id: id, className: cn(selectStyles.grouplabel({ size }), className), ref: ref, ...props, children: children }));
});
export const ItemSeparator = forwardRef(({ className, ...props }, ref) => {
    return (_jsx(S.Separator, { "data-scope": "select", "data-part": "separator", className: cn(selectStyles.separator(), className), ref: ref, ...props }));
});
export const Hint = forwardRef(({ children, className, ...props }, ref) => {
    const { size, disabled, isInvalid, isSuccess } = useSelect();
    const state = getComponentState({
        isDisabled: disabled,
        isInvalid,
        isSuccess,
    });
    return (_jsx("p", { ref: ref, className: cn(selectStyles.hint({ state, size }), className), ...props, children: children }));
});
export const SelectTrigger = ({ leftIcon, placeholder, className }) => {
    const { id, name, size } = useSelect();
    return (_jsxs(SelectAnatomy.Trigger, { className: cn(selectStyles.trigger({
            size,
        }), className), id: id, name: name, children: [leftIcon && (_jsx(SelectAnatomy.Icon, { children: cloneElement(leftIcon, {
                    className: selectStyles.icon({
                        size,
                    }),
                }) })), _jsx(SelectAnatomy.Value, { placeholder: placeholder, className: "text-left" }), _jsx(SelectAnatomy.Icon, { className: "ml-auto", children: _jsx(ChevronDownLine, { className: selectStyles.icon({
                        size,
                    }) }) })] }));
};
export const Portal = S.Portal;
export const SelectAnatomy = {
    Root,
    Trigger,
    Icon,
    Value,
    Content,
    ItemGroup,
    GroupLabel,
    Option: Item,
    Hint,
    Label: InputLabel,
    ItemSeparator,
    Portal,
};
/**
 * Composed Simplified instance of the Select component
 */
export const Select = ({ size, disabled, isInvalid, isSuccess, placeholder = 'Select', children, className, leftIcon, name, id, label, value, onValueChange, hint, rightIcon, useIndicator = false, isOptional = false, contentClassName, ...props }) => {
    const items = Children.map(children, child => child &&
        cloneElement(child, {
            size,
        }));
    const state = getComponentState({
        isDisabled: disabled,
        isInvalid,
        isSuccess,
    });
    return (_jsx(SelectAnatomy.Root, { value: value, onValueChange: onValueChange, name: name, disabled: disabled, "aria-label": name, useIndicator: useIndicator, size: size, className: className, ...props, children: _jsxs(FormControl, { label,
            name,
            state,
            hint,
            optional: isOptional,
            size, children: [_jsxs(SelectAnatomy.Trigger, { className: cn(selectStyles.trigger({
                        size,
                    }), className), id: id, name: name, children: [leftIcon && (_jsx(SelectAnatomy.Icon, { children: cloneElement(leftIcon, {
                                className: selectStyles.icon({
                                    size,
                                }),
                            }) })), _jsx(SelectAnatomy.Value, { placeholder: placeholder, className: "text-left" }), _jsx(SelectAnatomy.Icon, { className: "ml-auto", children: _jsx(ChevronDownLine, { className: selectStyles.icon({
                                    size,
                                }) }) })] }), _jsx(SelectAnatomy.Portal, { children: _jsx(SelectAnatomy.Content, { className: contentClassName, children: items }) })] }) }));
};
/**
 * Simplified Select Item
 */
export const SelectItem = Item;
export const SelectGroupLabel = GroupLabel;
export const SelectGroup = ItemGroup;
export const SelectSeparator = ItemSeparator;
export const SelectPortal = Portal;
