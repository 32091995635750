function setRef(ref, value) {
    if (typeof ref === 'function') {
        ref(value);
    }
    else if (ref !== null && ref !== undefined) {
        // prettier-ignore
        ref.current = value;
    }
}
/** Resolves all refs passed as arguments to a single value. */
export function composeRefs(...refs) {
    return (node) => refs.forEach(ref => setRef(ref, node));
}
/**
 * Calls all functions passed as arguments with the same arguments.
 * This is useful for event handlers.
 */
export const callAll = (...fns) => (...a) => {
    fns.forEach(function (fn) {
        fn?.(...a);
    });
};
const clsx = (...args) => args
    .map(str => str?.trim())
    .filter(Boolean)
    .join(' ');
const eventRegex = /^on[A-Z]/;
/**
 * Merges props together, with the last prop taking precedence.
 * @param args - The props to merge.
 */
export function mergeProps(...args) {
    const result = {};
    for (const props of args) {
        for (const key in result) {
            if (eventRegex.test(key) &&
                typeof result[key] === 'function' &&
                typeof props[key] === 'function') {
                result[key] = callAll(result[key], props[key]);
                continue;
            }
            if (key === 'className' || key === 'class') {
                result[key] = clsx(result[key], props[key]);
                continue;
            }
            if (key === 'style') {
                result[key] = Object.assign({}, result[key] ?? {}, props[key] ?? {});
                continue;
            }
            result[key] = props[key] !== undefined ? props[key] : result[key];
        }
        // Add props from b that are not in a
        for (const key in props) {
            if (result[key] === undefined) {
                result[key] = props[key];
            }
        }
    }
    return result;
}
