'use client';
import { jsx as _jsx } from "react/jsx-runtime";
import { Root as LabelRoot } from '@radix-ui/react-label';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { cva } from 'class-variance-authority';
export const labelStyles = cva('font-medium block', {
    variants: {
        isDisabled: {
            true: 'text-text-field-disabled',
            false: 'text-text-field-label-text',
        },
        size: {
            sm: 'text-field-label-sm',
            md: 'text-field-label-md',
            lg: 'text-field-label-lg',
            xl: 'text-field-label-xl',
        },
    },
    defaultVariants: {
        isDisabled: false,
        size: 'md',
    },
});
export const InputLabel = ({ label, isVisible = true, name, isDisabled, size, }) => {
    if (!label)
        return null;
    const Label = (_jsx(LabelRoot, { className: labelStyles({
            size,
            isDisabled,
        }), htmlFor: name, children: label }));
    return isVisible ? Label : _jsx(VisuallyHidden.Root, { children: Label });
};
