'use client';
import { createContext } from '../../utils/component.context';
export const [SelectProvider, useSelect] = createContext({
    name: 'SelectContext',
    hookName: 'useSelect',
    providerName: 'SelectProvider',
    strict: true,
    defaultValue: {
        size: 'md',
        setSelectValue(value) { },
    },
});
export const [SelectGroupProvider, useSelectGroup] = createContext({
    name: 'SelectGroupContext',
    hookName: 'useSelectGroup',
    providerName: 'SelectGroupProvider',
    strict: true,
    defaultValue: {
        type: 'neutral',
    },
});
