import { cva } from 'class-variance-authority';
const wrapper = cva('flex flex-col gap-100');
const trigger = cva([
    'inline-flex items-center leading-none font-component-bold placeholder:font-component',
    'bg-select hover:bg-select-hover active:bg-select-press',
    'text-select data-[placeholder]:text-select-placeholder',
    'border-select hover:border-select-hover active:border-select-press',
    'focus-visible:ring-select-focus ring-inset outline-none focus-visible:border-transparent',
    'data-[disabled]:bg-select-disabled data-[disabled]:text-select-disabled data-[disabled]:border-select data-[disabled]:border-select-disabled',
], {
    variants: {
        size: {
            sm: 'h-select-sm px-select-sm rounded-select-sm gap-select-sm-x select-text-sm',
            md: 'h-select-md px-select-md rounded-select-md gap-select-md-x select-text-md',
            lg: 'h-select-lg px-select-lg rounded-select-lg gap-select-lg-x select-text-lg',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
const content = cva([
    'overflow-hidden bg-select-modal shadow-select-list p-select-list',
    '[&:has([data-scope=select][data-part=itemgroup])]:p-0',
    '[&:has([data-scope=dropdown][data-part=itemgroup])]:p-0',
    '[&:has([data-scope=dropdowncommand][data-part=root])]:p-0',
    '[&:has([data-scope=contextmenu][data-part=itemgroup])]:p-0',
    'z-10',
], {
    variants: {
        size: {
            sm: 'rounded-select-list-sm',
            md: 'rounded-select-list-md',
            lg: 'rounded-select-list-lg',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
const item = cva([
    'font-component-bold outline-none',
    'leading-none rounded-select flex items-center w-full select-none data-[disabled]:text-select-disabled data-[disabled]:pointer-events-none disabled:text-select-disabled disabled:pointer-events-none',
    'transition-colors duration-100 ease-in-out',
    'text-ellipsis line-clamp-1 whitespace-pre min-w-0',
    'cursor-pointer',
], {
    variants: {
        size: {
            sm: 'h-select-sm px-select-sm rounded-select-sm gap-select-sm-x select-list-item-sm',
            md: 'h-select-md px-select-md rounded-select-md gap-select-md-x select-list-item-md',
            lg: 'h-select-lg px-select-lg rounded-select-lg gap-select-lg-x select-list-item-lg',
        },
        type: {
            neutral: 'text-select-content active:bg-select-list-item-neutral-press hover:bg-select-list-item-neutral-hover data-[highlighted]:bg-select-list-item-neutral-press data-[selected]:bg-select-list-item-neutral-press',
            destructive: 'text-select-hint-error hover:bg-select-list-item-destructive-hover active:bg-select-list-item-destructive-press data-[highlighted]:bg-select-list-item-destructive-press',
        },
    },
    defaultVariants: {
        size: 'md',
        type: 'neutral',
    },
});
const icon = cva('', {
    variants: {
        size: {
            sm: 'h-select-icon-sm w-select-icon-sm',
            md: 'h-select-icon-md w-select-icon-md',
            lg: 'h-select-icon-lg w-select-icon-lg',
        },
    },
    defaultVariants: {
        size: 'sm',
    },
});
const group = cva(['p-select-list']);
const separator = cva(['border-b border-separator flex-shrink-0']);
const grouplabel = cva(['text-tertiary flex items-center'], {
    variants: {
        size: {
            sm: 'h-select-sm px-select-sm select-label-sm',
            md: 'h-select-md px-select-md select-label-md',
            lg: 'h-select-lg px-select-lg select-label-lg',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export const kbd = cva(['text-tertiary flex items-center ml-auto'], {
    variants: {
        size: {
            sm: 'h-select-sm pl-select-sm select-label-sm',
            md: 'h-select-md pl-select-sm select-label-md',
            lg: 'h-select-lg pl-select-sm select-label-lg',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
const hint = cva('font-light ', {
    variants: {
        state: {
            default: 'text-select-hint',
            error: 'text-select-hint-error',
            success: 'text-select-hint',
            disabled: 'text-select-disabled',
        },
        size: {
            sm: 'select-hint-sm',
            md: 'select-hint-md',
            lg: 'select-hint-lg',
            xl: 'select-hint-xl',
        },
    },
    defaultVariants: {
        state: 'default',
        size: 'md',
    },
});
export const selectStyles = {
    wrapper,
    trigger,
    content,
    item,
    icon,
    hint,
    group,
    grouplabel,
    separator,
    kbd,
};
