'use client';
import { createContext } from '../../utils/component.context';
export const [DropdownProvider, useDropdown] = createContext({
    name: 'DropdownContext',
    hookName: 'useDropdown',
    providerName: 'DropdownProvider',
    strict: true,
    defaultValue: {
        size: 'md',
    },
});
export const [DropdownGroupProvider, useDropdownGroup] = createContext({
    name: 'DropdownGroupContext',
    hookName: 'useDropdownGroup',
    providerName: 'DropdownGroupProvider',
    strict: true,
    defaultValue: {
        type: 'neutral',
    },
});
export const [DropdownCommandProvider, useDropdownCommand] = createContext({
    name: 'DropdownCommandContext',
    hookName: 'useDropdownCommand',
    providerName: 'DropdownCommandProvider',
    strict: true,
    defaultValue: {
        size: 'md',
    },
});
