'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as D from '@radix-ui/react-dropdown-menu';
import { Children, cloneElement, forwardRef } from 'react';
import { CheckLine } from '@zealy/icons';
import { withBaseIcon } from '@zealy/icons/dist/src/Icons.utils';
import { cn } from '../../utils/cn';
import { getComponentState } from '../../utils/getComponentState';
import { Checkbox } from '../Checkbox';
import { InputLabel } from '../InputLabel';
import { z } from '../Primitive';
import { ItemSeparator as SelectItemSeparator } from '../Select';
import { DropdownGroupProvider, DropdownProvider, useDropdown, useDropdownGroup, } from './Dropdown.context';
import { dropdownStyles } from './DropdownMenu.styles';
/**
 * The DropdownMenu Root component used to wrap the dropdown menu and it's corresponding context state
 */
const Root = forwardRef(({ className, id, name, size = 'md', disabled, children, ...props }, ref) => {
    const context = {
        id,
        name,
        size,
        disabled,
    };
    return (_jsx(DropdownProvider, { value: context, children: _jsx("div", { "data-scope": "dropdown", "data-part": "root", className: cn(dropdownStyles.wrapper(), className), children: _jsx(D.Root, { ...props, children: children }) }) }));
});
const Icon = forwardRef(({ children, className, size: sizeHTML, ...props }, ref) => {
    const { size } = useDropdown();
    const icon = Children.only(children);
    return withBaseIcon(cloneElement(icon, {
        ref,
        className: cn(dropdownStyles.icon({
            size: size,
        }), icon.props.className, className),
    }), {
        ...props,
        size: sizeHTML,
    });
});
const Trigger = forwardRef(({ children, ...props }, ref) => {
    const { id, name, disabled: isDisabled } = useDropdown();
    return (_jsx(D.Trigger, { "data-scope": "dropdown", "data-part": "trigger", disabled: isDisabled, id: id, name: name, ref: ref, ...props, children: children }));
});
const Portal = ({ children, ...props }) => {
    return (_jsx(D.Portal, { "data-scope": "dropdown", "data-part": "portal", ...props, children: children }));
};
const Content = forwardRef(({ className, children, ...props }, ref) => {
    const { size } = useDropdown();
    return (_jsx(D.Content, { "data-scope": "dropdown", "data-part": "content", className: cn(dropdownStyles.content({
            size,
        }), className), ref: ref, side: "bottom", align: "start", sideOffset: 8, ...props, children: children }));
});
/**
 * Container component for the ItemGroup
 */
const ItemGroup = forwardRef(({ className, children, type, ...props }, ref) => {
    return (_jsx(DropdownGroupProvider, { value: { type }, children: _jsx(D.Group, { "data-scope": "dropdown", "data-part": "itemgroup", className: cn(dropdownStyles.group(), className), ref: ref, ...props, children: children }) }));
});
/**
 * Item component for the base dropdown item
 */
const Item = forwardRef(({ className, children, onSelect, size: sizeProp, type: typeProp, leftIcon, rightIcon, ...props }, ref) => {
    const { size: sizeCtx } = useDropdown();
    const { type: typeGroupCtx } = useDropdownGroup();
    const size = sizeProp ?? sizeCtx;
    const type = typeProp ?? typeGroupCtx;
    return (_jsxs(D.Item, { "data-scope": "dropdown", "data-part": "item", className: cn(dropdownStyles.item({
            size,
            type,
        }), className), onSelect: onSelect, ...props, ref: ref, children: [leftIcon && _jsx(Icon, { children: leftIcon }), children, rightIcon && _jsx(Icon, { children: rightIcon })] }));
});
const RadioGroup = forwardRef(({ children, className, ...props }, ref) => (_jsx(D.RadioGroup, { "data-scope": "dropdown", "data-part": "radiogroup", ref: ref, ...props, className: className, children: children })));
const GroupLabel = forwardRef(({ className, children, ...props }, ref) => {
    const { id, size } = useDropdown();
    return (_jsx(D.Label, { "data-scope": "select", "data-part": "grouplabel", id: id, className: cn(dropdownStyles.grouplabel({ size }), className), ref: ref, ...props, children: children }));
});
const RadioItem = forwardRef(({ className, children, onSelect, closeOnSelect = false, size: sizeProp, type: typeProp, leftIcon, rightIcon, ...props }, ref) => {
    const { size: sizeCtx } = useDropdown();
    const { type: typeGroupCtx } = useDropdownGroup();
    const size = sizeProp ?? sizeCtx;
    const type = typeProp ?? typeGroupCtx;
    function handleOnSelect(event) {
        if (!closeOnSelect) {
            event.preventDefault();
        }
        if (onSelect) {
            onSelect(event);
        }
    }
    return (_jsxs(D.RadioItem, { "data-scope": "dropdown", "data-part": "item", className: cn(dropdownStyles.item({
            size,
            type,
        }), className), onSelect: handleOnSelect, ...props, ref: ref, children: [_jsx(z.div, { className: cn(dropdownStyles.icon({ size })), children: _jsx(D.ItemIndicator, { children: _jsx(CheckLine, { className: cn(dropdownStyles.icon({ size })) }) }) }), leftIcon && _jsx(Icon, { children: leftIcon }), children, rightIcon && _jsx(Icon, { children: rightIcon })] }));
});
const CheckboxItem = forwardRef(({ className, children, onSelect, closeOnSelect = false, size: sizeProp, type: typeProp, leftIcon, rightIcon, checked, onCheckedChange, ...props }, ref) => {
    const { size: sizeCtx } = useDropdown();
    const { type: typeGroupCtx } = useDropdownGroup();
    const size = sizeProp ?? sizeCtx;
    const type = typeProp ?? typeGroupCtx;
    function handleOnSelect(event) {
        if (!closeOnSelect) {
            event.preventDefault();
        }
        if (onSelect) {
            onSelect(event);
        }
    }
    return (_jsxs(D.CheckboxItem, { "data-scope": "dropdown", "data-part": "item", className: cn(dropdownStyles.item({
            size,
            type,
        }), '!pl-0', className), onSelect: handleOnSelect, checked: checked, onCheckedChange: onCheckedChange, ...props, ref: ref, children: [_jsx(Checkbox, { checked: checked, indicatorContainerClass: "group-hover:bg-transparent group-active:bg-transparent" }), leftIcon && _jsx(Icon, { children: leftIcon }), children, rightIcon && _jsx(Icon, { children: rightIcon })] }));
});
const ItemSeparator = SelectItemSeparator;
ItemSeparator.displayName = 'DropdownMenu.ItemSeparator';
const Hint = forwardRef(({ children, className, ...props }, ref) => {
    const { size, disabled: isDisabled, isInvalid, isSuccess } = useDropdown();
    const state = getComponentState({
        isDisabled,
        isInvalid,
        isSuccess,
    });
    return (_jsx("p", { className: cn(dropdownStyles.hint({ state, size }), className), ...props, ref: ref, children: children }));
});
const Label = (props) => _jsx(InputLabel, { ...props });
const Sub = ({ children, ...props }) => (_jsx(D.Sub, { ...props, children: children }));
const SubTrigger = forwardRef(({ children, ...props }, ref) => {
    return (_jsx(D.SubTrigger, { "data-scope": "dropdown", "data-part": "subtrigger", ...props, ref: ref, children: children }));
});
const SubContent = forwardRef(({ children, className, ...props }, ref) => {
    const { size } = useDropdown();
    return (_jsx(D.SubContent, { "data-scope": "dropdown", "data-part": "subcontent", className: cn(dropdownStyles.content({
            size,
        }), className), ref: ref, sideOffset: 8, ...props, children: children }));
});
export const DropdownMenuAnatomy = {
    Root,
    Trigger,
    Icon,
    Content,
    ItemGroup,
    GroupLabel,
    Item,
    ItemSeparator,
    Hint,
    RadioGroup,
    RadioItem,
    Label,
    CheckboxItem,
    Sub,
    SubTrigger,
    SubContent,
    Portal,
};
