import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Children, cloneElement, forwardRef, isValidElement } from 'react';
import { composeRefs, mergeProps } from './Primitive.utils';
/**
 * This is a helper function to create a component that can be used as a child of another component.
 * It will pass all props to the child component, except for the `asChild` prop.
 * If the `asChild` prop is set to `true`, it will pass all props to the child component's child.
 *
 * This is similar to the primitive components in the Radix UI architecture.
 *
 * @param Component - The component to wrap
 * @returns A component that can be used as a child of another component
 * @example
 * ```tsx
 * const AccordionTrigger = withAsChild((props: BaseAccordionTriggerProps) => {
 *   // ...
 *   return (
 *     <button {...props}>{children}</button>
 *   )
 * })
 *
 * const App = () => (
 *  // This will forward props of the AccordionTrigger to the button
 *  <AccordionTrigger asChild>
 *    <button>
 *      Open accordion
 *    </button>
 *  </AccordionTrigger>
 * )
 * ```
 */
export const withAsChild = (Component) => {
    const Comp = forwardRef((props, ref) => {
        const { asChild, children, ...restProps } = props;
        if (!asChild) {
            return _jsx(Component, { ...props, ref: ref });
        }
        const onlyChild = Children.only(children);
        return isValidElement(onlyChild)
            ? cloneElement(onlyChild, {
                ...mergeProps(restProps, onlyChild.props),
                ref: ref ? composeRefs(ref, onlyChild.ref) : onlyChild.ref,
            })
            : null;
    });
    // @ts-expect-error - it exists
    Comp.displayName = Component.displayName || Component.name;
    return Comp;
};
export const primitivesFactory = () => {
    const cache = new Map();
    return new Proxy(withAsChild, {
        apply(target, thisArg, argArray) {
            return withAsChild(argArray[0]);
        },
        get(_, element) {
            const asElement = element;
            if (!cache.has(asElement)) {
                cache.set(asElement, withAsChild(asElement));
            }
            return cache.get(asElement);
        },
    });
};
/**
 * Exports a primitives factory for creating HTML elements that can forward props with `asChild`.
 *
 * @example
 * ```tsx
 * const App = () => {
 *  return (
 *   // z.div can be used with `asChild`
 *   <z.div>
 *    Hello world
 *   </z.div>
 * }
 */
export const z = primitivesFactory();
